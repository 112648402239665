<template>






  <div class="fondEcran">
  
  
    <div class="centerAlign">
  
      <nav>
              
      
      
      
          <!-- <img v-if="menuMasque" src="./assets/ArbreMenu2.png" class="feuilleMenu"> -->
          
          <img v-if="menuMasque" src="./assets/FeuilleMenuA.png" class="feuilleMenuA">
          <img v-if="menuMasque" src="./assets/FeuilleMenuB.png" class="feuilleMenuB">
          <img v-if="menuMasque" src="./assets/FeuilleMenuC.png" class="feuilleMenuC">
          <img v-if="menuMasque" src="./assets/FeuilleMenuD.png" class="feuilleMenuD">
          <img v-if="menuMasque" src="./assets/FeuilleMenuE.png" class="feuilleMenuE">
          <img v-if="menuMasque" src="./assets/FeuilleMenuF.png" class="feuilleMenuF">
          <img v-if="menuMasque" src="./assets/FeuilleMenuG.png" class="feuilleMenuG">
          
        
  
            <a @click="actionBoutonMenuMasque"  >
            <button  class=" boutonMasquerLeMenu" 
                  type="button" >
                  {{nomMenuMasque}}
            </button>
            </a> 
  
  
  
            
            <router-link  to="/" >
  
  
            <button  v-if="menuMasque" v-on:click="ramenerDebutPage" class="button boutonAcceuil"
                  type="button" >
                  
                  <img src="./assets/FeuilleBouton.png" class="FeuilleBoutonAccueil">
              Accueil 
            
            </button>
          </router-link>
        
      
          <router-link to="/Presentation">
              <button v-if="menuMasque"  v-on:click="ramenerDebutPage"  class="button boutonPresentation"
                  type="button" >
                  <img src="./assets/FeuilleBouton.png" class="FeuilleBoutonPresentation">
              Présentation
            </button>
          </router-link>
      
          <router-link to="/PrestationTarifs">
              <button v-if="menuMasque"  v-on:click="ramenerDebutPage"  class="button boutonTarifs "
                  type="button">
                  <img src="./assets/FeuilleBouton.png" class="FeuilleBoutonTarifs">
              Prestations et Tarifs 
            </button>
          </router-link>
      
          <router-link to="/Contact">
              <button v-if="menuMasque"  v-on:click="ramenerDebutPage"  class="button boutonContact"
                  type="button">
                  <img src="./assets/FeuilleBouton.png" class="FeuilleBoutonContact">
              Contact
            </button>
          </router-link>
      
          
  
  
      </nav>
    </div>
  
  
  
      <router-view/>
  
    
  </div>
  
  </template>
  
  
  
  <script>
  
  export default {
    name: 'App',
   
    data: ()=> ({
      menuMasque: true,
      nomMenuMasque: "Masquer le menu",
  
    }),
    components: {
      
    },
    methods: {
          actionBoutonMenuMasque: function () {
            if (this.menuMasque) {
             this.nomMenuMasque = "Afficher le menu";
              this.menuMasque = false ;
            }
             else 
             {
              this.menuMasque = true ;
              this.nomMenuMasque = "Masquer le menu";
             }
          
          },
  
          ramenerDebutPage: function () {
            window.location.href = "#";
            //this.body.scrollTop
          },
          actionLeMassageAPrixTresDoux: function () {
            if (this.ArticleLeMassageAPrixTresDoux) {
              this.ArticleLeMassageAPrixTresDoux = false ;
            }
             else 
             {
              this.ArticleLeMassageAPrixTresDoux = true ;
             }
          
          },
          actionMassageAuGite: function () {
            if (this.ArticleMassageAuGite) {
              this.ArticleMassageAuGite = false ;
            }
             else 
             {
              this.ArticleMassageAuGite = true ;
             }
          
          },
          actionAtelierSophroMassage: function () {
            if (this.ArticleAtelierSophroMassage) {
              this.ArticleAtelierSophroMassage = false ;
            }
             else 
             {
              this.ArticleAtelierSophroMassage = true ;
             }
          
          },
          actionReductionMassage: function () {
            if (this.ArticleReductionMassage) {
              this.ArticleReductionMassage = false ;
            }
             else 
             {
              this.ArticleReductionMassage = true ;
             }
          
          }
      }
  }
    
  
  
  
    
    </script>
  
  
  
  
  <style>
  
  
  @import url('https://fonts.cdnfonts.com/css/papyrus');
  @import url('//db.onlinewebfonts.com/c/a0e1b1883c0cf520e9c50d0cd91cd0d0?family=PapyrusW01');
  @import url('http://fonts.cdnfonts.com/css/palatino');
  
  
  /* Modification Laura cépe*/
  
  
  
  .LeMassageAPrixTresDoux {
   margin: 0em 0em 0em 0em;
    padding: 0em 0em 0em 0em;
    
    
  
  }
  .MassageAuGite{
    
    width: 100%;
  }
  
  .LeMassageAPrixTresDoux{
    width: 100%;
    
  }
  
  .AtelierSophroMassage{
    
    width: 100%;
  }
  
  .DefinitionLignesArticles{
    margin: auto;
    
    justify-content: center;
    align-items: center;
  }
  
  
  
  .LignePremiereLigneArticles{
  
    justify-content: center;
    width: 100%;
    margin-right: 0em;
    z-index: -1 ;
  
    margin-bottom: 3.5em;
  }
  
  .LigneDeuxiemesLigneArticles{
  
    width: 100%;
    display: flex;
    
    justify-content: center;
  
    margin-bottom: 3.5em;
    
  }
  
  .texteArticle{
  
  
   
    font-size: 1.8em;
      text-align:center;
      margin-right: auto;
      margin-left: auto;
      color: rgb(0, 0, 0);
      
   /* background-image:url("~@/assets/FeuilleBonneTaille.png");*/
  }
  
    .texteArticleMassageAuGite{
      
      font-size: 1.6em;
      top: -0.5em;
      margin-bottom: 0em;
    }
  
  
  .paragrapheArticleMassageAuGite{
   
    top: -0em;
  }
  
  .paragrapheArticle{
    
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 1em 1em 1em 1em;
    margin: -1.2em  1em 1em 1em;
  }
  
  
  /*BoutonFeuilleArticle*/
  .boutonFeuilleArticle{
  

    height:10em;

  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }
  
  .imageFeuilleArticle {
    
    z-index: revert;
    left: 10em;
  top: 10em;
   
   object-fit: cover;
  height: 10em;
  }
  /*Fin BoutonFeuilleArticle*/
  
  
  /* Modification Laura 02 */
  .FeuilleInterlude{
    height: 3.7em;
  }
  
  .paddingA{
    padding: 0em;
  }
  
  .marginA{
    margin: 0em 2em 0em 2em;
  }
  
  .FeuilleInterludePrestationEtTarifs {
    margin: 0em 0em 3em 0em;
    height: 3.7em;
  }
  
  .mailLaura{
    color: #000000;
  }
  
  
  /* Fin Modification Laura 02 */
  
  
  /*style Acceuil */
  .BlancContenuAcceuil{
      display: flex;
      
      align-items: center;
      justify-content: center;
      
    
    }
    
    .contenuAcceuil{
      width:  80%;
      margin-bottom: 4em;
      padding: 2em 2em 2em 2em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    
    .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
    }
    
    .TexteContenuAcceuil{
      margin: 1.5em;
      text-shadow: 0 0 1px rgb(0, 0, 0);
    }
    
    .texteBonjourEtBienvenu{
      font-size: 3em;
      color: black;
    }
    
    .texteVertMassage{
      font-size: 3em;
      color: #000000;
    }
    
    .texteMassageSalonOuDomicile{
      font-size: 3em;
      color: #000000;
    }
    
    .texteAdresse{
      font-size: 2em;
      color: #000000;
    }
    
    .texteTel{
      font-size: 2em;
      color: #000000;
    }
    
    .texteMassageOuvertDu{
      font-size: 2em;
      color: #000000;
    }
    
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.5em;
      color: #000000;
    }
  
    .texteAcceuilParagrapheB{
      padding: 0em 2em 1em 2em;
      font-size: 1.5em;
      color: #000000;
    }
    
    .texteAGaillac{
      font-size: 1.5em;
    }
    
    .texteADomicile {
      font-size: 1.5em;
    }
    
    .texteEnVert{
      color: #000000;
    }
    
    
    
    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 0em 0em 0em 0em;
    }
    
  
    
    .texteLogoPetit{
      font-size: 0.8em;
    }
    
    .telephoneAcceuil{
      color:#000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
    }
    
    
    
    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #42b983;
    }
  /*fin style Acceuil */
  
  
  
  
  
  /*style de Prestaion et tarifs*/
  .TitreMassageCaliforniens{
    font-size: 3em;
    color: #000;
  }
  
  
   .BlancContenuPrestationTarifs{
      display: flex;
      
      align-items: center;
      justify-content: center;
    
    
    }
    
    .contenuPrestationTarifs{
      width:  80%;
      margin-bottom: 4em;
      padding: 2em 2em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
  
  
    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
    }


    .DiminutionDesBr{
      height: 010em;
    }
  /*fin Prestation et tarifs*/
  
  
  /*presentation*/
  
  .TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 3em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 2em ;
  
      text-align: start;
  }
  
  
    
    .BlancContenuPresentation{
      display: flex;
      
      align-items: center;
      justify-content: center;
    
    
    }
    
    .contenuPresentation{
      width:  80%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    
  
    
    
  
    
    
    
  
    
    .texteLogoPetit{
      font-size: 0.8em;
    }
    

    
    
    
    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #42b983;
    }
  /*fin presentation*/
      
  .CouleurVert{
    color: #000000;
    
  }
  
  .CouleurVertClaire{
    color: #000000;
    
  }

  .PaddingB{
    padding: 0em 01em 0em 01em;
  }
  
  .CouleurBlanc{
    color: #a14343;
    text-shadow: 0 0 1px rgb(0, 0, 0)
  }
  
  .CouleurNoir{
    color: #000000;
    text-shadow: 0 0 1px rgb(0, 0, 0)
  }

  .CouleurRouge{
    color: #000000;
    /*text-shadow: 0 0 1px rgb(0, 0, 0)*/
  }
  
  .PaddingEtMargin0{
    margin:01em;
    padding: 0em;
  }
  
  .azure{
    color: #0abaff;
    text-shadow: 0 0 1px rgb(0, 0, 0)
    }
  
  .TexteContenuPresentation{
    padding: 2em 1em 1em 1em;
    font-size: 1.5em;
    color: #000000;
  }
      
  .TexteBasique{
    font-size: 1.6em;
    color: #000000;
  }
  
  .TextePapyrusse{
    font-family:'Papyrus','PapyrusW01', sans-serif;
  }
  
  .TexteSize2-5em{
    font-size: 2.5em;
  }
  
  .TexteSize1-9em{
    font-size: 2.2em;
  }
  
  .margin0{
    margin: 0;
  }
  .marginBottom0{
    margin-bottom: 0em;
  }
      
  .PaddingChoix{
    padding-left: 1em;
    padding-top: 0em;
  }
      
      body{
        /*font-family: 'Roboto Condensed', sans-serif; 
        font-family: 'MV Boli', sans-serif;   'MV Boli' */
        font-family: 'Palatino', sans-serif;
        
      }
      
      .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 3.5em  0em 0em 0.8em;
    }
  
  .massageCaliforniensAcceuil{
    font-size: 1.8em;
  }
      
  
      .VertsMassagesLogo{
      
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 4.5em;
      color: #b3e9b1;
      margin-left: 0.8em;
      
    
    }
  
      .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 9em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: -0.50em;
    }
  
  
  
  
  .centerAlign{
    align-items: center;
    position:fixed;
    
  }
      
  nav{
        
        position: relative;
        
        width: 720px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        left:30em;
      }
   
      
  
  
  
      
  .fondEcran{
          margin: 0em;
          padding: 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: 100em;
          background-position: center;
          
          z-index: auto;
          
      }
      
      .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 1.4em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }
      
      .boutonMasquerLeMenu{
        
      background-color: #00000050;
          border: 0.15em solid #000000;
          border-radius: 1em;
          padding: 0.5em 1em 0.5em 1em;
          color: rgb(255, 255, 255);
  
          z-index: 11;
         position: absolute;
         right:02em ;
         top: 01em;
         cursor : pointer;
      }
      
      .feuilleMenu{
        position: absolute;
         right:0em ;
        width: 100%;
        z-index: 9;
        
      }
      
     
      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 214px ;
        z-index: 9;
      }
  
      .feuilleMenuC{
        position: absolute;
         right:301px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
         right:386px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
         right:459px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
         right:594px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
         right:620px ;
        z-index: 9;
       
      }
  
  
  
      .FeuilleBoutonAccueil{
        position: absolute;
        z-index: -2;
        right: -1.5em;
        top: -0.2em;
        height: 3.7em;
        cursor : pointer;
      }
      
      .FeuilleBoutonPresentation{
        position: absolute;
        z-index: -1;
        right: -0em;
        top: -0.2em;
        height: 3.7em;
        cursor : pointer;
      }
  
      .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 0.8em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }
  
      .FeuilleBoutonContact{
        position: absolute;
        z-index: -1;
        right: -1.2em;
        top: -0.2em;
        height: 3.7em;
        cursor : pointer;
        
      }
  
      
      .boutonAcceuil{
        position: absolute;
         right:025em ;
         top: 01em;
         cursor : pointer;
      }
      
      .boutonPresentation{
        position: absolute;
         right:012em ;
         top: 00.5em;
         cursor : pointer;
      }
      
      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         cursor : pointer;
      }

      .fontSizeBoutonTarif{
        font-size: 0.2em;
      }
      
      .boutonContact{
        position: absolute;
         right:2em ;
         top: 010em;
         cursor : pointer;
      }
      
  
      
      
      #app {
        font-family:'Palatino', cursive, Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #502c37;
        margin-top: 0em;
        margin-bottom: 0em;
       right: 0em;
        display: flex;
          align-items: center;
          justify-content: center;
      
          width: 1000px;
      }
      
      body{
        
        display: flex;
          align-items: center;
          justify-content: center;

        margin: 0em 0em 0em 0em;
      }
  
      .hello{
    width: 75em;
    
  }
  
  .helloContact{
    width: 75em;
    height: 80em;
  }
  
  /* fin app */
  
  
  
  
  
  
  
  
  
  /* media queris*/
  
  
  
  @media (min-width: 1200px){
   /* .fondEcran{
          margin: 0em;
          padding: 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: 100em;
          background-position: center;
          
          z-index: auto;
          
      }
  */
  .marginTop{
    margin-top: 5em ;
  }
  .marginTopContact {
    margin-top: -2em ;
  }

  }
  
  @media (min-width: 1000px) and (max-width:1199px){
  
    .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 6em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: -0.50em;
    }

    .massageCaliforniensAcceuil{
    font-size: 1.4em;
  }


    .hello{
      width: 1000px;
    }
  
    .helloContact{
    width: 1000px;
    height: 100%;
  }
  
  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 08em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 3em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 4.5em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 1000px;
          background-position: center;
          
          z-index: auto;
          
      }
  /* */
  .centerAlign{
    align-items: center;
    position:fixed;
    
   
  } 
  
  nav{
    align-items: center;
        position: relative;
        
        width: 720px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        left:280px;
      } 
  
      .marginTopContact {
    margin-top: -4em ;
  }

  }
  
  @media (min-width: 760px) and (max-width:999px){
     .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 0em;
    }
  
    .helloContact{
    width: 100%;
    height: 100em;
  }
  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 06em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 6em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 1em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 760px;
          background-position: contain;
          
          z-index: auto;
          
      }



/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: -80em;

} 
  
  nav{
   
        position: relative;
        
        width: 280px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/*  */

/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 1em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 0.6em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }

      .TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      
  
      text-align: start;
  }

  .marginTopContact {
    margin-top: 0em ;
  }


  .marginTop{
    margin-top: 6em ;
  }

  }
  
  @media (min-width: 540px) and (max-width:759px){
     .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 0em;
      
    }


  
    .helloContact{
    width: 100%;
    height: 100em;
  }
  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 08em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }

  .massageCaliforniensAcceuil{
    font-size: 1em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0.5em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 540px;
          background-position: contain;
          
          z-index: auto;
          
      }

/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: -80em;

} 
  
  nav{
   
        position: relative;
        
        width: 60px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/*  */

/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 001em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonTarifs{
        position: absolute;
         right:-01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 3.2em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  
  .marginTop{
    margin-top: 6em ;
  }
  
}

  @media (min-width: 450px) and (max-width:539px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }

  .massageCaliforniensAcceuil{
    font-size: 1em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0.5em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }

/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/*  */

/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 01em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:019.5em ;
         top: 05em;
         cursor : pointer;
      }
      /* A rajouter dans les versions plus petite */
      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }


  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 2em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */


    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LignePremiereLigneArticles{
  
  justify-content: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  z-index: -1 ;

  margin-bottom: 0em;
}


  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0em;
  margin-left: 0em;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em auto 0em auto;
      padding: 0em auto 0em auto; 
      text-align: center;
      display: flex; 
      align-items: center;
  }
  }

  @media (min-width: 412px) and (max-width:449px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0.5em;
    }

    .massageCaliforniensAcceuil{
    font-size: 0.8em;
  }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }

/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit */


/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 01em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:017.5em ;
         top: 05em;
         cursor : pointer;
      }

      .boutonPresentation{
        position: absolute;
         right:010.5em ;
         top: 00.5em;
         cursor : pointer;
      }
      

      /* A rajouter dans les versions plus petite */
      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */


    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:-01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 3.2em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.2em;
  }

  .LignePremiereLigneArticles{
  
  justify-content: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  z-index: -1 ;

  margin-bottom: 0em;
}


  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0em;
  margin-left: 0em;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em auto 0em auto;
      padding: 0em auto 0em auto; 
      text-align: center;
      display: flex; 
      align-items: center;
  }
  }

  @media (min-width: 390px) and (max-width:411px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0em;
    }

    .massageCaliforniensAcceuil{
    font-size: 1em;
  }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }

/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/*  */
/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit */
/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 01em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:016em ;
         top: 07em;
         cursor : pointer;
      }

      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
      
    
    }

/* A rajouter dans les versions plus petite */

.TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.8em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 3em 0em 0em 01em;
  
      text-align: start;
  }

.BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */

    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em; 
      text-align: center;
      display: flex; 
      align-items: center;
  }

  .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
      font-size: 1.1em;
    }



  .sizeTextePresentationAcceuil{
    font-size: 1.5em;
  }

  .sizeOuverture{
    font-size: 1.1em;
  }

  .contenuAcceuil{
      width:  100%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }

    /*Page présentation */
    .contenuPresentation{
      width:  90%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    /*Page présentation fin*/
    
  .marginTop{
    margin-top: 6em ;
  }

  /*Page prestation et tarif */
    .TexteSize2-5em{
    font-size: 2em;
  }


  .TexteBasique{
    font-size: 1.3em;
    color: #000000;
  }

  .contenuPrestationTarifs{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }

    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }

  /*Page prestation et tarif fin */


    /* Article*/
    .DefinitionLignesArticles{
      
      margin-right: 0em;
      
      justify-content: center;
      align-items: center;
    }
  
        .boutonTarifs{
          position: absolute;
           right:-1em ;
           top: 4.5em;
           width: 18em;
           cursor : pointer;
        }

        .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 3.2em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }
  
        .TexteSize1-9em{
      font-size: 1.2em;
    }
  
    .LignePremiereLigneArticles{
    
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: -1 ;
  
    margin-bottom: 0em;
  }
  
  
    .LigneDeuxiemesLigneArticles{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0em;
    margin-left: 0em;
    margin-bottom: 3.5em;
    
  }
  
  .boutonFeuilleArticle{
    
    width: 18em;
    
    background-image:url("~@/assets/FeuilleBonneTaille.png");
    background-color: #00000000;
    background-position: center -2em;
    background-size: 18em;
    border: none;
        cursor: pointer;
        font-family:'Papyrus', sans-serif;
        margin: 0em auto 0em auto;
        padding: 0em auto 0em auto; 
        text-align: center;
        display: flex; 
        align-items: center;
    }
 /* Article fin*/
   
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.2em;
      color: #000000;
    }

    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 03em 0em 0em 0em;
    }


  }
  
  @media (min-width: 375px) and (max-width:389px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.5em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 3em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: -0.5em;
    }

    .massageCaliforniensAcceuil{
    font-size: 0.8em;
  }


  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }

/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/*  */
/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit fin */
/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 01em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:015em ;
         top: 07em;
         cursor : pointer;
      }

      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
      
    
    }

/* A rajouter dans les versions plus petite */

.TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.8em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 3em 0em 0em 01em;
  
      text-align: start;
  }

.BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */

    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em; 
      text-align: center;
      display: flex; 
      align-items: center;
  }

  .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
      font-size: 1.1em;
    }



  .sizeTextePresentationAcceuil{
    font-size: 1.5em;
  }

  .sizeOuverture{
    font-size: 1.1em;
  }

  .contenuAcceuil{
      width:  100%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }

    /*Page présentation */
    .contenuPresentation{
      width:  90%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    /*Page présentation fin*/


  /*Page prestation et tarif */
    .TexteSize2-5em{
    font-size: 2em;
  }


  .TexteBasique{
    font-size: 1.3em;
    color: #000000;
  }

  .contenuPrestationTarifs{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }

    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }

  /*Page prestation et tarif fin */


    /* Article*/
    .DefinitionLignesArticles{
      
      margin-right: 0em;
      
      justify-content: center;
      align-items: center;
    }
  
        .boutonTarifs{
          position: absolute;
           right:-01em ;
           top: 4.5em;
           width: 18em;
           cursor : pointer;
        }
  
        .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 3.2em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }

        .TexteSize1-9em{
      font-size: 1.2em;
    }
  
    .LignePremiereLigneArticles{
    
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: -1 ;
  
    margin-bottom: 0em;
  }
  
  
    .LigneDeuxiemesLigneArticles{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0em;
    margin-left: 0em;
    margin-bottom: 3.5em;
    
  }
  
  .boutonFeuilleArticle{
    
    width: 18em;
    
    background-image:url("~@/assets/FeuilleBonneTaille.png");
    background-color: #00000000;
    background-position: center -2em;
    background-size: 18em;
    border: none;
        cursor: pointer;
        font-family:'Papyrus', sans-serif;
        margin: 0em auto 0em auto;
        padding: 0em auto 0em auto; 
        text-align: center;
        display: flex; 
        align-items: center;
    }
 /* Article fin*/
   
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.2em;
      color: #000000;
    }

    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 03em 0em 0em 0em;
    }


  }
  
  @media (min-width: 360px) and (max-width:374px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 2em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }

      .massageCaliforniensAcceuil{
    font-size: 1em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 4em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }

/* Feuille Menu  */

  
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/*  */
/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit fin */
/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 01em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:014em ;
         top: 08em;
         cursor : pointer;
      }

      .boutonPresentation{
        position: absolute;
         right:011em ;
         top: 00.5em;
         cursor : pointer;
      }

      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
      
    
    }

/* A rajouter dans les versions plus petite */

.TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.8em;
      color: #000000;
      text-shadow: 0px 0px 1px #000000;
      margin: 3em 0em 0em 01em;
  
      text-align: start;
  }

.BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */

    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

  
      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em; 
      text-align: center;
      display: flex; 
      align-items: center;
  }

  .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
      font-size: 1.1em;
    }



  .sizeTextePresentationAcceuil{
    font-size: 1.5em;
  }

  .sizeOuverture{
    font-size: 1.1em;
  }

  .contenuAcceuil{
      width:  100%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }

    /*Page présentation */
    .contenuPresentation{
      width:  90%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    /*Page présentation fin*/


  /*Page prestation et tarif */
    .TexteSize2-5em{
    font-size: 2em;
  }


  .TexteBasique{
    font-size: 1.3em;
    color: #000000;
  }

  .contenuPrestationTarifs{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }

    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }

    .boutonTarifs{
          position: absolute;
           right:-1em ;
           top: 4.5em;
           width: 18em;
           cursor : pointer;
        }
  

        .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 3.2em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }

  /*Page prestation et tarif fin */


    /* Article*/
    .DefinitionLignesArticles{
      
      margin-right: 0em;
      
      justify-content: center;
      align-items: center;
    }
  
       
        .TexteSize1-9em{
      font-size: 1.2em;
    }
  
    .LignePremiereLigneArticles{
    
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: -1 ;
  
    margin-bottom: 0em;
  }
  
  
    .LigneDeuxiemesLigneArticles{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0em;
    margin-left: 0em;
    margin-bottom: 3.5em;
    
  }
  
  .boutonFeuilleArticle{
    
    width: 18em;
    
    background-image:url("~@/assets/FeuilleBonneTaille.png");
    background-color: #00000000;
    background-position: center -2em;
    background-size: 18em;
    border: none;
        cursor: pointer;
        font-family:'Papyrus', sans-serif;
        margin: 0em auto 0em auto;
        padding: 0em auto 0em auto; 
        text-align: center;
        display: flex; 
        align-items: center;
    }
 /* Article fin*/
   
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.2em;
      color: #000000;
    }

    .marginRight{
      margin-right: 1em;
    }

    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 03em 0em 0em 0em;
    }


  }

  @media (min-width: 275px) and (max-width:359px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.5em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }

  .massageCaliforniensAcceuil{
    font-size: 1em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 3.5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }


/* Feuille Menu  */
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/* Feuilles menu fin */

/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit fin */

/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 00.8em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:014em ;
         top: 08em;
         cursor : pointer;
      }

      .boutonPresentation{
        position: absolute;
         right:011em ;
         top: 00.5em;
         cursor : pointer;
      }

      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
      
    
    }

/* A rajouter dans les versions plus petite */

.TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.8em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 3em 0em 0em 01em;
  
      text-align: start;
  }

.BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */

    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }
      .FeuilleBoutonTarifs{
        position: absolute;
        z-index: -1;
        right: 3.2em;
        top: -0.2em;
        height: 3.7em;
        width: 11.5em;
        cursor : pointer;
      }


      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em; 
      text-align: center;
      display: flex; 
      align-items: center;
  }

  .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
      font-size: 1.1em;
    }



  .sizeTextePresentationAcceuil{
    font-size: 1.5em;
  }

  .sizeOuverture{
    font-size: 1.1em;
  }

  .contenuAcceuil{
      width:  100%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }

    /*Page présentation */
    .contenuPresentation{
      width:  90%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    /*Page présentation fin*/


  /*Page prestation et tarif */
    .TexteSize2-5em{
    font-size: 2em;
  }


  .TexteBasique{
    font-size: 1.3em;
    color: #000000;
  }

  .contenuPrestationTarifs{
      width:  90%;
      margin-bottom: 4em;
      
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }

    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }

    .boutonTarifs{
          position: absolute;
           right:-01em ;
           top: 4.5em;
           width: 18em;
           cursor : pointer;
        }
  

  /*Page prestation et tarif fin */


    /* Article*/
    .DefinitionLignesArticles{
      
      margin-right: 0em;
      
      justify-content: center;
      align-items: center;
    }
  
       
        .TexteSize1-9em{
      font-size: 0.9em;
    }
  
    .LignePremiereLigneArticles{
    
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: -1 ;
  
    margin-bottom: 0em;
  }
  
  
    .LigneDeuxiemesLigneArticles{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0em;
    margin-left: 0em;
    margin-bottom: 3.5em;
    
  }
  
  .boutonFeuilleArticle{
    
    width: 18em;
    
    background-image:url("~@/assets/FeuilleBonneTaille.png");
    background-color: #00000000;
    background-position: center -2em;
    background-size: 18em;
    border: none;
        cursor: pointer;
        font-family:'Papyrus', sans-serif;
        margin: 0em auto 0em auto;
        padding: 0em auto 0em auto; 
        text-align: center;
        display: flex; 
        align-items: center;
    }
 /* Article fin*/
   
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.2em;
      color: #000000;
    }

    .marginRight{
      margin-right: 1em;
    }

    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 03em 0em 0em 0em;
    }


  }

  @media (min-width: 200px) and (max-width:274px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 2.5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0.5em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }


/* Feuille Menu  */
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/* Feuilles menu fin */

/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit fin */

/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 00.8em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:010em ;
         top: 010em;
         cursor : pointer;
      }

      .boutonPresentation{
        position: absolute;
         right:08em ;
         top: 00.5em;
         cursor : pointer;
      }

      .boutonContact{
        position: absolute;
         right:2em ;
         top: 014em;
         cursor : pointer;
      }

      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
      
    
    }

/* A rajouter dans les versions plus petite */

.TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.8em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 3em 0em 0em 01em;
  
      text-align: start;
  }

.BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */

    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em; 
      text-align: center;
      display: flex; 
      align-items: center;
  }

  .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
      font-size: 1.1em;
    }



  .sizeTextePresentationAcceuil{
    font-size: 1.5em;
  }

  .sizeOuverture{
    font-size: 1.1em;
  }

  .contenuAcceuil{
      width:  100%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }

    /*Page présentation */
    .contenuPresentation{
      width:  90%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    /*Page présentation fin*/


  /*Page prestation et tarif */
    .TexteSize2-5em{
    font-size: 2em;
  }


  .TexteBasique{
    font-size: 1.3em;
    color: #000000;
  }

  .contenuPrestationTarifs{
      width:  90%;
      margin-bottom: 4em;
      
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }

    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }

    .boutonTarifs{
          position: absolute;
           right:01em ;
           top: 5.5em;
           width: 18em;
           cursor : pointer;
        }
  

  /*Page prestation et tarif fin */


    /* Article*/
    .DefinitionLignesArticles{
      
      margin-right: 0em;
      
      justify-content: center;
      align-items: center;
    }
  
       
        .TexteSize1-9em{
      font-size: 0.9em;
    }
  
    .LignePremiereLigneArticles{
    
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: -1 ;
  
    margin-bottom: 0em;
  }
  
  
    .LigneDeuxiemesLigneArticles{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0em;
    margin-left: 0em;
    margin-bottom: 3.5em;
    
  }
  
  .boutonFeuilleArticle{
    
    width: 18em;
    
    background-image:url("~@/assets/FeuilleBonneTaille.png");
    background-color: #00000000;
    background-position: center -2em;
    background-size: 18em;
    border: none;
        cursor: pointer;
        font-family:'Papyrus', sans-serif;
        margin: 0em auto 0em auto;
        padding: 0em auto 0em auto; 
        text-align: center;
        display: flex; 
        align-items: center;
    }
 /* Article fin*/
   
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.2em;
      color: #000000;
    }

    .marginRight{
      margin-right: 1em;
    }

    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 03em 0em 0em 0em;
    }


  }

  @media all and (max-width: 199px){
    .hello{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 13em;
      
    }
  
    .helloContact{
    width: 100%;
    height: 100em;

    margin:  0em 0em 0em 0em;
  }


  
.SansMarge{
  margin: 0em 0em 0em 0em;
}

  .texteLogo{
      font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 0em  0em 0em 01em;
    }
  
    .VertsMassagesLogo{
      margin-top: 02em;
      font-family:'Papyrus', sans-serif;
    
      display: flex;
      font-size: 0em;
      color: #b3e9b1;
      margin-left: 0.8em;
  }
  
  .VertsMassagesLogoAccueil{
      
      font-family:'Papyrus', sans-serif;
      justify-content: center;
      display: flex;
      font-size: 2.5em;
      color: #b3e9b1;
      
      text-shadow: 0 0 1px rgb(0, 0, 0);
    margin-top: 0.5em;
    }

  .fondEcran{
          margin: 0em 0em 0em 0em;
          padding: 0em 0em 0em 0em;
  
          right: 0em;
      
          background-image:url("~@/assets/FondEcran.jpg");
          background-attachment: fixed;
          
          background-repeat: no-repeat;
          background-size: cover;
          
          width: 100%;
          background-position: contain;
          
          z-index: auto;
          
      }


/* Feuille Menu  */
.centerAlign{

align-items: center;
position:fixed;
margin-right: 0em;
right:470px;
} 
  
  nav{
   
        position: relative;
        
        width: 00px;
        margin-right: 0em;
        padding:0em ;
        top: 0em;
        
        
       
      } 

      .feuilleMenuA{
        position: absolute;
         right:0px ;
        z-index: 9;
       
        width: 143px;
        
      }
  
      .feuilleMenuB{
        position: absolute;
         right: 143px ;
         width: 58px;
        z-index: 9;
        
      }
  
      .feuilleMenuC{
        position: absolute;
        width: 56px;
        right: 201px ;
        z-index: 9;
      }
      .feuilleMenuD{
        position: absolute;
        width: 48px;
        right: 257px ;
        z-index: 9;
      }
      .feuilleMenuE{
        position: absolute;
        width: 90px;
        right: 305px ;
        z-index: 9;
      }
      .feuilleMenuF{
        position: absolute;
        width: 17px;
        right: 395px ;
        z-index: 9;
      }
      .feuilleMenuG{
        position: absolute;
        width: 42px;
        right: 412px ;
        z-index: 9;
       
      }

/* Feuilles menu fin */

/* ContactPlusPetit */
.TexteSize1-9em{
    font-size: 1.2em;
  }
/* ContactPlusPetit fin */

/* Bouton Menu */

    .boutonMasquerLeMenu{
        
        background-color: #00000050;
            border: 0.15em solid #000000;
            border-radius: 1em;
            padding: 0.5em 1em 0.5em 1em;
            color: rgb(255, 255, 255);
            font-size: 0.7em;
            z-index: 11;
           position: absolute;
           right:02em ;
           top: 01em;
           cursor : pointer;
        }

    .button{
      font-family: 'Palatino', cursive;
      font-weight: bold;
      font-size: 00.8em;
      background: #00000000;
      border: #000000;
     
          
          padding: 1em 2em 1em 2em;
          color: #700086;
          z-index: 11;
          cursor: pointer;
      }

      .boutonAcceuil{
        position: absolute;
         right:010em ;
         top: 010em;
         cursor : pointer;
      }

      .boutonPresentation{
        position: absolute;
         right:08em ;
         top: 00.5em;
         cursor : pointer;
      }

      .boutonContact{
        position: absolute;
         right:2em ;
         top: 014em;
         cursor : pointer;
      }

      .BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
      
    
    }

/* A rajouter dans les versions plus petite */

.TitrePresentation{
    font-family:'Papyrus', sans-serif;
      text-align: start;
      font-size: 1.8em;
      color: #000000;
      text-shadow: 0 0 1px rgb(0, 0, 0);
      margin: 3em 0em 0em 01em;
  
      text-align: start;
  }

.BlancContenuAcceuil{
      display: flex;
      margin: auto auto auto auto;
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .contenuAcceuil{
      width:  90%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }
    .paragrapheArticle{
    
      width: 80%;
    color: #000;
    border: 0.2em solid #7eb07d ;
    border-radius: 2em;
    background-color: #b4e9b1;
    padding: 01em 01em 01em 01em;
    margin: -1.2em  auto 0em auto;
  }



  .boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    
  }

  .DiminutionDesBrPestationEtTarif{
      height: 00em;
    }


  .PaddingEtMargin0{
    margin:01em 01em 01em 01em;
    padding: 0em;
  }

  .PaddingChoix{
    padding-left: 0.0em;
    padding-top: 0em;
  }
      /* A rajouter dans les versions plus petite fin */

    /* Article*/
    .DefinitionLignesArticles{
      
    margin-right: 0em;
    
    justify-content: center;
    align-items: center;
  }

      .boutonTarifs{
        position: absolute;
         right:01em ;
         top: 4.5em;
         width: 18em;
         cursor : pointer;
      }

      .TexteSize1-9em{
    font-size: 1.7em;
  }

  .LigneDeuxiemesLigneArticles{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5em;
  
}

.boutonFeuilleArticle{
  
  width: 18em;
  
  background-image:url("~@/assets/FeuilleBonneTaille.png");
  background-color: #00000000;
  background-position: center -2em;
  background-size: 18em;
  border: none;
      cursor: pointer;
      font-family:'Papyrus', sans-serif;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em; 
      text-align: center;
      display: flex; 
      align-items: center;
  }

  .textesAcceuille {
      text-shadow: 0 0 1px rgb(0, 0, 0);
      font-size: 1.1em;
    }



  .sizeTextePresentationAcceuil{
    font-size: 1.5em;
  }

  .sizeOuverture{
    font-size: 1.1em;
  }

  .contenuAcceuil{
      width:  100%;
      margin-bottom: 4em;
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
      
    }

    /*Page présentation */
    .contenuPresentation{
      width:  90%;
      margin-bottom: 4em;
      
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }
    /*Page présentation fin*/


  /*Page prestation et tarif */
    .TexteSize2-5em{
    font-size: 2em;
  }


  .TexteBasique{
    font-size: 1.3em;
    color: #000000;
  }

  .contenuPrestationTarifs{
      width:  90%;
      margin-bottom: 4em;
      
      padding: 2em 0em 2em 0em;
      background-color: #ffffff9c;
      border-radius: 2em;
      border: 0.15em solid black;
    }

    .TexteContenuPrestationTarifs{
      font-family: 'Comic Neue', cursive;
      margin: 0em 0em 0em 0em;
      padding: 0em 0em 0em 0em;
    }

    .boutonTarifs{
          position: absolute;
           right:01em ;
           top: 5.5em;
           width: 18em;
           cursor : pointer;
        }
  

  /*Page prestation et tarif fin */


    /* Article*/
    .DefinitionLignesArticles{
      
      margin-right: 0em;
      
      justify-content: center;
      align-items: center;
    }
  
       
        .TexteSize1-9em{
      font-size: 0.9em;
    }
  
    .LignePremiereLigneArticles{
    
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: -1 ;
  
    margin-bottom: 0em;
  }
  
  
    .LigneDeuxiemesLigneArticles{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0em;
    margin-left: 0em;
    margin-bottom: 3.5em;
    
  }
  
  .boutonFeuilleArticle{
    
    width: 18em;
    
    background-image:url("~@/assets/FeuilleBonneTaille.png");
    background-color: #00000000;
    background-position: center -2em;
    background-size: 18em;
    border: none;
        cursor: pointer;
        font-family:'Papyrus', sans-serif;
        margin: 0em auto 0em auto;
        padding: 0em auto 0em auto; 
        text-align: center;
        display: flex; 
        align-items: center;
    }
 /* Article fin*/
   
    .texteAcceuilParagraphe{
      padding: 0em 2em 1em 2em;
      font-size: 1.2em;
      color: #000000;
    }

    .marginRight{
      margin-right: 1em;
    }

    .conteneurTexteLogo{
      
      left: 0.8em;
      padding-top:0em ;
      padding: 03em 0em 0em 0em;
    }


  }
  
  
  
  
  
  
  
  </style>
  